<!--
   /**
      * becomeBusinessman.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p class="bigTitle">成为商家</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Become a businessman</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="10" class="margin_t_20">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="margin_b_40">
                        <p class="textP">富融物创是一个开放性的平台，除了核心的物业管理功能，还具备多系统接入的支持，实现接入各种商家的服务，并有流程机制对相关的第三方商家进行审核。</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">服务费用</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Service fee</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/fee.png" style="width:100px" /></p>
                        <p class="serviceS colorBlue">每项目每月￥200.00</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">申请流程</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">APPLICATION PROCESS</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/one.png"/></p>
                        <p class="serviceS colorBlue">供应商招募</p>
                        <p class="serviceM">公开发行招募信息，广招各路优质供应商</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/two.png"/></p>
                        <p class="serviceS colorBlue">供应商考察</p>
                        <p class="serviceM">对供应商进行考察，包括其基本信息、考察方案和目标设定、考察流程记录等各项指标</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/three.png"/></p>
                        <p class="serviceS colorBlue">供应商评估</p>
                        <p class="serviceM">对供应商做出评分管理，筛选出优质的供应商</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/four.png"/></p>
                        <p class="serviceS colorBlue">服务接入</p>
                        <p class="serviceM">加入供应商名册，接入优质供应商，为物业用户提供各种服务</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
